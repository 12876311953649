<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field promotionName">
            <div class="title">프로모션명</div>
            <ul class="content">
              <li class="item">
                <input-text
                  ref="searchpromotionName"
                  v-model="searchConditions.promtnName"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              buttonDiv="GET"
              :isShortcutButton="true"
              :ignore="isPopupOpened"
              @click.native="onViewButtonClicked"
          >
            조회
          </erp-button>
        </div>
      </div>
      <div class="lookup-right">
        <div class="lookup-detail">
          <erp-button
              buttonDiv="GET"
              :isIconCustom="true"
              @click.native="searchDetail"
          >
            상세검색
          </erp-button>
        </div>
        <ul class="lookup-button">
          <li class="shortcut">
            <ejs-dropdownbutton
              ref="shortcutMenuDropdownButton"
              cssClass="lookup-button-dropdown"
              :items="shortcutMenuItems"
              :select="shortcutMenuClicked"
            >
              바로가기
            </ejs-dropdownbutton>
          </li>
        </ul>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">프로모션 목록</div>
              <div class="header-caption">[{{ count }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="add">
                  <erp-button
                      buttonDiv="SAVE"
                      :ignore="isPopupOpened"
                      :is-custom-shortcut-button="true"
                      :shortcut-key="'PromotionRegistration.shortcuts.save'"
                      :shortcut="{key:'F3'}"
                      :isIconCustom="true"
                      @click.native="onPromotionAddClicked"
                  >
                    추가
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      buttonDiv="FILE"
                      @click.native="onClickExcel"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <ejs-grid-wrapper
              ref="promotionGrid"
              id="promotionGrid"
              :provides="gridProvides"
              :columns="promotionGridColumns"
              :enableVirtualization="false"
              :dataSource="promotionList"
              :allowPaging="true"
              :allowSelection="true"
              :allowExcelExport="true"
              :selectionSettings="promotionGridSelectionSettings"
              :editSettings="promotionGridEditSettings"
              :pageSettings="promotionGridPageSettings"
              :allowResizing="true"
              :rowHeight="24"
              @queryCellInfo="promotionGridCustomiseCell"
              @headerCellInfo="onPromotionGridHeaderCellInfo"
              @rowSelecting="onPromotionGridRowSelecting"
              @actionComplete="promotionGridActionComplete"
              @recordClick="promotionGridRecordClick"
            />
          </div>
        </section>
      </article>
    </div>
    <ejs-dialog
      ref="searchDetailPopup"
      :header="`상세검색`"
      :allowDragging="true"
      :showCloseIcon="true"
      width="387"
      :animationSettings="{ effect: 'None' }"
      :isModal="false"
      :visible="false"
      enableResize="true"
    >
      <div class="window lookupDetail-promotionRegistration">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookupDetail">
              <ul class="lookupDetail-condition">
                <li class="field">
                  <div class="title">영업구분</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                        ref="bsnCodeDropdown"
                        v-model="searchConditions.bsnCode"
                        :dataSource="searchConditions.bsnCodeOptions"
                        :allowFiltering="false"
                        :fields="{ text: 'comName', value: 'comCode' }"
                        cssClass="lookupDetail-condition-dropdown"
                      ></ejs-dropdownlist>
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">프로모션 구분</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                        ref="promtnDivOptions"
                        v-model="searchConditions.promtnDiv"
                        :dataSource="searchConditions.promtnDivOptions"
                        :allowFiltering="false"
                        :fields="{ text: 'comName', value: 'comCode' }"
                        cssClass="lookupDetail-condition-dropdown"
                      ></ejs-dropdownlist>
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">적용일자</div>
                  <ul class="content">
                    <li class="item">
                      <ul class="check">
                        <li>
                          <label>
                            <input
                              type="checkbox"
                              id="useDate"
                              v-model="searchConditions.dateUseFlag"
                              value="true"
                            />
                            <i></i>
                            <div class="label">사용</div>
                          </label>
                        </li>
                      </ul>
                    </li>
                    <li class="item dateRange">
                      <input-date-range
                        v-model="searchDateRange"
                        type="lookupDetail-condition"
                        :disabled="!searchConditions.dateUseFlag"
                      />
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">사용여부</div>
                  <ul class="content">
                    <li class="item check">
                      <ul class="check">
                        <li>
                          <label>
                            <input
                              type="radio"
                              id="useFlagAll"
                              v-model="searchConditions.useFlag"
                            />
                            <i></i>
                            <div class="label">ALL</div>
                          </label>
                        </li>
                        <li>
                          <label>
                            <input
                              type="radio"
                              id="useFlagUse"
                              value="true"
                              v-model="searchConditions.useFlag"
                            />
                            <i></i>
                            <div class="label">사용</div>
                          </label>
                        </li>
                        <li>
                          <label>
                            <input
                              type="radio"
                              id="useFlagNotUse"
                              value="false"
                              v-model="searchConditions.useFlag"
                            />
                            <i></i>
                            <div class="label">미사용</div>
                          </label>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="lookup keyColor">
              <erp-button
                  buttonDiv="GET"
                  :isShortcutButton="true"
                  :ignore="isPopupOpened"
                  @click.native="onDialogViewButtonClicked"
              >
                조회
              </erp-button>
            </li>
            <li class="reset">
              <erp-button
                  buttonDiv="GET"
                  @click.native="onDialogSearchConditionInitClicked"
                  :isIconCustom="true"
              >
                초기화
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  buttonDiv="CLOSE"
                  @click.native="onDialogSearchDetailClicked"
                  :isIconCustom="true"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <promotion-info-popup
      v-if="isPromotionAddPopupOpen"
      ref="promotionInputPopup"
      :position="{ x: 'center', y: 'center' }"
      :isModal="true"
      popupKey=""
      v-on:popupEvent="onPromotionAddPopupEvent"
      @rowSelect="onPromotionGridRowSelect"
      @basePopupEvent="onBasePopupEvent"
    />
  </div>
</template>

<style scoped>
body .appContent .lookup-condition .field.promotionName .content .item {width: 180px;}
body .appContent .body-article .section-body {overflow: hidden;border: none;}
</style>

<script>
import Vue from "vue";

import ErpButton from "@/components/button/ErpButton.vue";
import commonMixin from "@/views/layout/mixin/commonMixin";
import {commonCodesGetColorValue, commonCodesGetCommonCode, commonCodesGetComName} from '@/utils/commonCodes';
import { getFormattedDate } from "@/utils/date";
import { numberWithCommas } from "@/utils/number";
import { getPromotionList } from "@/api/promotion";
import promotionInfoPopup from "./popup/PromotionInfoPopup";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import InputDateRange from "@/components/common/datetime/InputDateRange";

import {
  Edit,
  VirtualScroll,
  Resize,
  ForeignKey,
  Page,
  ExcelExport,
} from "@syncfusion/ej2-vue-grids";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import InputText from "@/components/common/text/InputText";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import { openNewWindow } from "@/utils/appInfo";

export default {
  name: "promotionRegistration",
  components: {
    InputText,
    promotionInfoPopup,
    EjsGridWrapper,
    InputDateRange,
    ErpButton
  },
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  data() {
    return {
      count: 0,
      isPromotionAddPopupOpen: false,
      isSearchDetailPopupOpen: false,
      classCodeFields: { text: "className", value: "classCode" },
      commonCodeFields: { text: "comName", value: "comCode" },
      promotionListOptions: {
        promtnDivOptions: [],
        bsnCode: null,
        paymtCodeOptions: [],
      },
      searchConditions: {
        bsnCode: null,
        bsnCodeOptions: [], // 영업구분
        promtnDiv: null,
        promtnDivOptions: [], // 프로모션구분
        dateUseFlag: null, // 날짜검색 사용여부
        startDate: null,
        endDate: null,
        useFlag: null, // 사용/미사용 선택여부
        promtnName: null,
      },
      promotionGridPageSettings: { pageSize: 50 },
      savedRowIndex: 0,
      numberTemplate() {
        // TODO : 공통 모듈로??
        return {
          template: Vue.component("editOption", {
            template: "<span>{{parseInt(data.index) + 1}}</span>",
            data() {
              return {
                data: {
                  data: {},
                },
              };
            },
          }),
        };
      },
      dwValueColumns: [
        {
          field: "dwValue2",
          headerText: "월",
          minWidth: "16",
          width: "50",
          editType: "booleanedit",
          type: "boolean",
          displayAsCheckBox: "true",
          textAlign: "Center",
        },
        {
          field: "dwValue3",
          headerText: "화",
          minWidth: "16",
          width: "50",
          editType: "booleanedit",
          type: "boolean",
          displayAsCheckBox: "true",
          textAlign: "Center",
        },
        {
          field: "dwValue4",
          headerText: "수",
          minWidth: "16",
          width: "50",
          editType: "booleanedit",
          type: "boolean",
          displayAsCheckBox: "true",
          textAlign: "Center",
        },
        {
          field: "dwValue5",
          headerText: "목",
          minWidth: "16",
          width: "50",
          editType: "booleanedit",
          type: "boolean",
          displayAsCheckBox: "true",
          textAlign: "Center",
        },
        {
          field: "dwValue6",
          headerText: "금",
          minWidth: "16",
          width: "50",
          editType: "booleanedit",
          type: "boolean",
          displayAsCheckBox: "true",
          textAlign: "Center",
        },
        {
          field: "dwValue7",
          headerText: "토",
          minWidth: "16",
          width: "50",
          editType: "booleanedit",
          type: "boolean",
          displayAsCheckBox: "true",
          textAlign: "Center",
        },
        {
          field: "dwValue1",
          headerText: "일",
          minWidth: "16",
          width: "50",
          editType: "booleanedit",
          type: "boolean",
          displayAsCheckBox: "true",
          textAlign: "Center",
        },
      ],
      boolParams: { params: { checked: true } },
      promotionList: [],
      promotionGridSelectionSettings: { mode: "Both" },
      promotionGridEditSettings: {
        allowEditing: false,
        allowAdding: false,
        allowDeleting: false,
        mode: "Batch",
        showConfirmDialog: false,
      },
      promotionLength: 0,
      promotionNo: "",
      gridProvides: [
        Edit,
        VirtualScroll,
        Resize,
        ForeignKey,
        Page,
        ExcelExport,
      ],
      addPromtnId: null,
    };
  },
  computed: {
    isPopupOpened() {
      return this.isPromotionAddPopupOpen;
    },
    shortcutMenuItems() {
      return [
        {
          id: 1,
          text: "타임테이블 생성",
        },
      ];
    },
    etcMenuItems() {
      return [
        {
          text: "다운로드",
        },
        {
          text: "인쇄하기",
        },
      ];
    },
    promotionGridColumns() {
      return [
        {
          field: "promtnId",
          allowEditing: false,
          type: "number",
          isPrimaryKey: true,
          visible: false,
        },
        {
          field: "promtnDiv",
          headerText: "프로모션",
          allowEditing: false,
          type: "string",
          minWidth: 16,
          width: 120,
          editType: "dropdownedit",
          textAlign: "left",
          isCommonCodeField: true,
          dataSource: this.promotionListOptions.promtnDivOptions,
        },
        {
          field: "promtnName",
          headerText: "프로모션명",
          allowEditing: false,
          type: "string",
          minWidth: 16,
          width: 150,
          textAlign: "left",
        },
        {
          field: "memberGradeName",
          headerText: "회원등급",
          allowEditing: false,
          type: "string",
          minWidth: 16,
          width: 220,
          textAlign: "left",
        },
        {
          field: "startEndDate",
          headerText: "적용일자",
          allowEditing: false,
          type: "string",
          minWidth: 16,
          width: 157,
          textAlign: "center",
        },
        {
          field: "resveCourseName",
          headerText: "출발코스",
          allowEditing: false,
          type: "string",
          minWidth: 16,
          width: 180,
        },
        {
          field: "startEndTime",
          headerText: "적용시간",
          allowEditing: false,
          type: "string",
          minWidth: 16,
          width: 90,
          textAlign: "center",
        },
        {
          field: "bsnCode",
          headerText: "영업구분",
          allowEditing: false,
          type: "string",
          minWidth: 16,
          width: 90,
          editType: "dropdownedit",
          textAlign: "center",
          isCommonCodeField: true,
          dataSource: this.promotionListOptions.bsnCodeOptions,
        },
        {
          headerText: "요일",
          allowEditing: false,
          width: 80,
          columns: this.dwValueColumns,
        },
        {
          field: "dcMethod",
          headerText: "할인방법",
          type: "string",
          minWidth: 16,
          width: 120,
          textAlign: "center",
          isCommonCodeField: true,
          groupCode: "DC_METHOD",
        },
        {
          field: "dcAmt",
          headerText: "할인내용",
          type: "string",
          minWidth: 16,
          width: 120,
          textAlign: "right",
          valueAccessor: (field, data) => {
            let val = null;
            if(data["dcMethod"] === "AMT") {
              val = `${numberWithCommas(data[field])}원`;
            } else if(data["dcMethod"] === "RATE") {
              val = `${data[field]}%`;
            } else {
              val =  commonCodesGetComName("PAYMT_CODE", data["paymtCode"]) || "-";
            }
            return val;
          },
        },
        {
          field: "paymtCode",
          headerText: "요금코드",
          allowEditing: false,
          type: "string",
          minWidth: 16,
          width: 120,
          editType: "dropdownedit",
          textAlign: "left",
          isCommonCodeField: true,
          dataSource: this.promotionListOptions.paymtCodeOptions,
          visible: false,
        },
        {
          field: "remarks",
          headerText: "비고",
          type: "string",
          minWidth: 16,
          width: 220,
        },
        {
          field: "useFlag",
          headerText: "사용",
          allowEditing: false,
          type: "boolean",
          minWidth: 16,
          width: 60,
          editType: "booleanedit",
          textAlign: "center",
          displayAsCheckBox: true,
        },
      ];
    },
    searchDateRange: {
      get() {
        return {
          from: this.searchConditions.startDate,
          to: this.searchConditions.endDate,
        };
      },
      set(searchDateRange) {
        this.searchConditions.startDate = searchDateRange.from;
        this.searchConditions.endDate = searchDateRange.to;
      },
    },
  },
  beforeMount() {
    this.detailPageDataLoad();
  },
  created() {
    // 적용일자사용여부
    this.searchConditions.dateUseFlag = false;
    this.searchConditions.startDate = getFormattedDate(new Date());
    this.searchConditions.endDate = getFormattedDate(new Date());
  },
  mounted() {
    // 기본 포커싱
    this.$refs.searchpromotionName.focus();
    this.searchConditions.bsnCodeOptions = commonCodesGetCommonCode(
      "BSN_CODE",
      true
    );
    //  영업구분 휴장 제외
    var newBsnCode = this.searchConditions.bsnCodeOptions.filter(
      (element) => element.comCode !== "CLOSE"
    );
    this.searchConditions.bsnCodeOptions = newBsnCode;

    if (
      this.searchConditions.bsnCodeOptions.findIndex(
        (obj) => obj.comCode === "ALL"
      ) === -1
    ) {
      this.searchConditions.bsnCodeOptions.unshift({
        comCode: "%",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: false,
      });
    }

    this.searchConditions.bsnCode = "%";

    this.searchConditions.promtnDivOptions = commonCodesGetCommonCode(
      "PROMTN_DIV",
      true
    );
    if (
      this.searchConditions.promtnDivOptions.findIndex(
        (obj) => obj.comCode === "ALL"
      ) === -1
    ) {
      this.searchConditions.promtnDivOptions.unshift({
        comCode: "%",
        comName: "전체",
        codeAbrv: "",
        defaultFlag: false,
      });
    }
    this.searchConditions.promtnDiv = "%";
  },
  methods: {
    commonCodesGetComName,
    numberWithCommas,
    promotionGridCustomiseCell(args) {
      const {
        column: {field},
        cell,
        data,
      } = args;
      if (field === "promtnName") {
        cell.classList.add(this.$t("className.grid.clickArea"));
        cell.style.color = args.data.colorValue;
      }
      if (field === "bsnCode") {
        cell.style.color = commonCodesGetColorValue(
          "BSN_CODE",
          args.data.bsnCode
        );
        if (data.bsnCode === "%") {
          cell.innerText = "전체";
        }
      }
    },
    onPromotionGridRowSelecting(args) {
      this.savedRowIndex = args.rowIndex;
      const $el = document.querySelector("#promotionInfoPopupSave");
      if ($el) {
        args.cancel = true;
      }
    },
    promotionGridRecordClick(args) {
      const {
        column: {
          field
        },
        rowData
      } = args;

      if (field === "promtnName") {
        this.isPromotionAddPopupOpen = true;
        this.$nextTick(() => {
          this.$refs.promotionInputPopup.showPopup(rowData.promtnId);
        });
      }
    },
    onPromotionGridHeaderCellInfo(args) {
      if (args.cell.column.field === "notaxCode") {
        // 헤더에 필수영역 표시 클래스 추가
        args.node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
      if (args.cell.column.field === "promtnName") {
        args.node.classList.add(this.$t("className.grid.clickArea"));
      }
    },
    searchDetail() {
      this.$refs.searchDetailPopup.show();
      this.isSearchDetailPopupOpen = true;
    },
    shortcutMenuClicked(args) {
      switch (args.item.id) {
        case 1: // 타임테이블 생성 새 창
          // eslint-disable-next-line no-case-declarations
          let routeData = this.$router.resolve({
            name: "timeTableCreate",
          });
          openNewWindow(routeData);
          break;
        default:
          break;
      }
    },
    onPromotionAddClicked() {
      this.isPromotionAddPopupOpen = true; // 팝업을 띄울 때 생성되도록 하기 위함
      this.$nextTick(() => {
        this.$refs.promotionInputPopup.showPopup();
      });
    },
    detailPageDataLoad() {
      this.setPromotionList();
    },
    onCallSaveAPI() {
      return new Promise((resolve) => {
        // TODO : 수정 API 호출 후 상황에 따라 resolve 또는 reject
        resolve();
      });
    },
    handlePopupClose() {
      this.isPromotionAddPopupOpen = false;
      this.$nextTick(() => {
        this.$refs["promotionGrid"].selectRow(this.savedRowIndex);
      });
    },
    onBasePopupEvent(eventId) {
      if (eventId === "promotionManagementSaved") {
        this.detailPageDataLoad(this.$router.currentRoute.params);
      } else if (eventId === "promotionManagementSavedByAdd") {
        this.detailPageDataLoad(this.$router.currentRoute.params);
      } else if (eventId === "promotionManagementDeleted") {
        this.detailPageDataLoad(this.$router.currentRoute.params);
        this.handlePopupClose();
      }
    },
    onDialogViewButtonClicked() {
      if (this.searchConditions.dateUseFlag) {
        if (
          this.searchConditions.startDate === null ||
          this.searchConditions.endDate === null
        ) {
          this.errorToast("취득일자를 입력해주세요");
        } else {
          this.setPromotionList();
        }
      } else {
        this.setPromotionList();
      }
    },
    onDialogSearchConditionInitClicked() {
      // 영업구분 초기화
      this.searchConditions.bsnCode = "%";

      // 적용일자사용여부
      this.searchConditions.dateUseFlag = false;

      // 프로모션구분 초기화
      this.searchConditions.promtnDiv = "%";

      // 적용일자 초기화
      this.searchConditions.startDate = getFormattedDate(new Date());
      this.searchConditions.endDate = getFormattedDate(new Date());

      // 사용여부
      this.searchConditions.useFlag = null;
    },
    onPromotionAddPopupEvent(eventId, popupKey, value, promtnId) {
      if (eventId === "popupClosed") {
        this.handlePopupClose();
      } else if (eventId === "promotionManagementSaved") {
        this.detailPageDataLoad(this.$router.currentRoute.params);
      } else if (eventId === "promotionManagementSavedByAdd") {
        this.detailPageDataLoad(this.$router.currentRoute.params);
        this.addPromtnId = promtnId;
      }
    },
    onViewButtonClicked() {
      this.setPromotionList();
    },
    onDialogSearchDetailClicked() {
      this.$refs.searchDetailPopup.hide();
      this.isSearchDetailPopupOpen = false;
    },
    setPromotionList() {
      getPromotionList(
        this.searchConditions.promtnName,
        this.searchConditions.dateUseFlag,
        this.searchConditions.startDate,
        this.searchConditions.endDate,
        this.searchConditions.bsnCode,
        this.searchConditions.promtnDiv,
        this.searchConditions.useFlag
      )
        .then((response) => {
          // row 선택 유지 TODO : 공통 모듈로 뺴기
          this.promotionList = response.value.promotionList;

          this.promotionLength = this.promotionList.length;
        })
        .catch((error) => {
          console.error(error);
        });
      // this.promotionList = []
      // 프로모션구분
      this.promotionListOptions.promtnDivOptions = commonCodesGetCommonCode(
        "PROMTN_DIV"
      );
      // 영업구분
      this.promotionListOptions.bsnCodeOptions = commonCodesGetCommonCode(
        "BSN_CODE"
      );
      // 요금코드
      this.promotionListOptions.paymtCodeOptions = commonCodesGetCommonCode(
        "PAYMT_CODE"
      );
    },
    promotionGridActionComplete(args) {
      this.count = numberWithCommas(
        this.$refs.promotionGrid?.getGridBatchCount() || 0
      );
      if (args.requestType === "refresh") {
        if (this.addPromtnId !== null) {
          this.onPromotionGridRowSelect(this.addPromtnId);
        }
      }
    },
    onPromotionGridRowSelect(promtnId) {
      const rowIndex = this.$refs.promotionGrid.getRowIndexByPrimaryKey(
        promtnId
      );

      if (rowIndex >= 0) {
        this.$refs.promotionGrid.selectRow(rowIndex);
        this.addPromtnId = null;
      }
    },
    onClickExcel() {
      this.$refs.promotionGrid.excelExport();
    },
  },
};
</script>
