<template>
  <div>
    <ejs-dialog
      ref="promotionInfoPopupDialog"
      header="프로모션 등록"
      :animationSettings="animationSettings"
      allowDragging="true"
      showCloseIcon="true"
      width="500"
      :close="dialogClose"
      v-on:mousedown.native="onDialogClicked"
      :position="popupPosition"
      :zIndex="currentzIndex"
      :isModal="$props.isModal"
      :visible="isVisible"
      :cssClass="
        promotionInfo.promtnId
          ? $t('className.popup.productCodePopupModify')
          : $t('className.popup.productCodePopupAdd')
      "
    >
      <div class="window promotionRegistration">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">정보 입력</div>
                    </div>
                    <div class="header-right">
                      <ul class="header-button">
                        <li class="delete">
                          <erp-button
                              buttonDiv="DELETE"
                              :is-shortcut-button="true"
                              @click.native="onDeleteClicked"
                          >
                            삭제
                          </erp-button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-data">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">프로모션 구분</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        ref="promtnDivDropdown"
                                        id="promtnDivDropdown"
                                        v-model="promotionInfo.promtnDiv"
                                        :dataSource="
                                          promotionInfoOptions.promtnDivOptions
                                        "
                                        :allowFiltering="false"
                                        :fields="commonCodeFields"
                                        cssClass="body-data-dropdown"
                                      ></ejs-dropdownlist>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">프로모션명</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                        id="promtnNmInput"
                                        name="promtnNmInput"
                                        ref="promtnNmInput"
                                        @keydown="preventEnterEvent"
                                        v-model="promotionInfo.promtnName"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">회원등급</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <!--<ejs-multiselect ref='memberGradeMultiSelect' v-on:keydown="preventEnterEvent" id='multiselect' :dataSource='promotionInfoOptions.memberGradeOptions' :fields='commonCodeFields' cssClass='body-data-multiselect'>-->
                                      <ejs-multiselect
                                        ref="memberGradeMultiSelect"
                                        v-model="promotionInfo.memberGradeList"
                                        id="multiselect"
                                        :dataSource="
                                          promotionInfoOptions.memberGradeOptions
                                        "
                                        :fields="commonCodeFields"
                                        cssClass="body-data-multiselect"
                                      ></ejs-multiselect>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field effectiveDate">
                              <!-- 필수 : required -->
                              <div class="title required">적용일자</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group dateRange">
                                    <div class="form">
                                      <input-date-range
                                        ref="promotionDateInput"
                                        type="body-data"
                                        v-model="promotionDateRange"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">영업구분</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        ref="bsnCodeDropdown"
                                        id="bsnCodeDropdown"
                                        v-model="promotionInfo.bsnCode"
                                        :dataSource="
                                          promotionInfoOptions.bsnCodeOptions
                                        "
                                        :allowFiltering="false"
                                        :fields="commonCodeFields"
                                        cssClass="body-data-dropdown"
                                      ></ejs-dropdownlist>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field dayOfWeekDivision">
                              <!-- 필수 : required -->
                              <div class="title required">요일구분</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group check">
                                    <!-- 포커스 : focus -->
                                    <ul class="check">
                                      <li>
                                        <label>
                                          <input
                                            type="checkbox"
                                            id="dwValueAll"
                                            v-model="dwValueAllValueModel"
                                          />
                                          <i></i>
                                          <div class="label">전체</div>
                                        </label>
                                      </li>
                                      <li
                                        :key="code.comCode"
                                        v-for="code in promotionInfoOptions.dwCodeOptions"
                                        :class="code.codeAbrv.toLowerCase()"
                                      >
                                        <label>
                                          <input
                                            :ref="`dwCode_${code.comCode}`"
                                            type="checkbox"
                                            :key="`checkbox_${code.comCode}`"
                                            :id="code.comCode"
                                            v-model="promotionInfo.dwValue"
                                            :value="code.comCode"
                                          />
                                          <i></i>
                                          <div
                                            class="label"
                                            :key="`label_checkbox_${code.comCode}`"
                                            :for="code.comCode"
                                          >
                                            {{ code.comName }}
                                          </div>
                                        </label>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">출발코스</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group check">
                                    <!-- 포커스 : focus -->
                                    <ul class="check">
                                      <li>
                                        <label>
                                          <input
                                            type="checkbox"
                                            id="resveCourseAll"
                                            v-model="resveCourseAllValueModel"
                                          />
                                          <i></i>
                                          <div class="label">전체</div>
                                        </label>
                                      </li>
                                      <li
                                        :key="code.comCode"
                                        v-for="code in promotionInfoOptions.courseCodeOptions"
                                      >
                                        <label>
                                          <input
                                            :ref="`courseCode_${code.comCode}`"
                                            type="checkbox"
                                            :key="`checkbox_${code.comCode}`"
                                            :id="code.comCode"
                                            v-model="promotionInfo.resveCourseList"
                                            :value="code.comCode"
                                          />
                                          <i></i>
                                          <div
                                            class="label"
                                            :key="`label_checkbox_${code.comCode}`"
                                            :for="code.comCode"
                                          >
                                            {{ code.comName }}
                                          </div>
                                        </label>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field reservationTime">
                              <!-- 필수 : required -->
                              <div class="title required">예약시간</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group time">
                                    <div class="form">
                                      <input-time
                                        ref="startTimeInput"
                                        :format="'HH:mm'"
                                        v-model="promotionInfo.startTime"
                                        @change="onStartTimeChange"
                                      />
                                    </div>
                                  </li>
                                  <li class="item form-group dash">~</li>
                                  <li class="item form-group time">
                                    <div class="form">
                                      <input-time
                                        ref="endTimeInput"
                                        :format="'HH:mm'"
                                        v-model="promotionInfo.endTime"
                                        @change="onEndTimeChange"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">홀구분</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        ref="holeDivDropdown"
                                        name="holeDivDropdown"
                                        v-model="promotionInfo.holeDiv"
                                        :dataSource="
                                          promotionInfoOptions.holeDivOptions
                                        "
                                        :allowFiltering="false"
                                        :fields="commonCodeFields"
                                        cssClass="body-data-dropdown"
                                      ></ejs-dropdownlist>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">팀 그린피</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-number
                                        v-model="promotionInfo.teamGreenFee"
                                        v-on:keydown.native="preventEnterEvent"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">팀 카트피</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-number
                                        v-model="promotionInfo.teamCartFee"
                                        v-on:keydown.native="preventEnterEvent"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title required">할인 방법</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        ref="dcMethodDropdown"
                                        name="dcMethodDropdown"
                                        v-model="promotionInfo.dcMethod"
                                        :dataSource="
                                          promotionInfoOptions.dcMethodOptions
                                        "
                                        :allowFiltering="false"
                                        :fields="commonCodeFields"
                                        cssClass="body-data-dropdown"
                                        @change="onDcMethodChange"
                                      ></ejs-dropdownlist>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div
                                  class="title"
                                  :class="promotionInfo.dcMethod ==='PAYMT' && 'required'"
                              >
                                요금코드
                              </div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <ejs-dropdownlist
                                        ref="paymtCodeDropdown"
                                        name="paymtCodeDropdown"
                                        v-model="promotionInfo.paymtCode"
                                        :dataSource="
                                          promotionInfoOptions.paymtCodeOptions
                                        "
                                        :allowFiltering="false"
                                        :fields="commonCodeFields"
                                        cssClass="body-data-dropdown"
                                        :enabled="paymtCodeEnabled"
                                      ></ejs-dropdownlist>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div
                                  class="title"
                                  :class="promotionInfo.dcMethod !=='PAYMT' && 'required'"
                              >
                                금액/할인율
                              </div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-number
                                        ref="dcAmtInput"
                                        v-model="promotionInfo.dcAmt"
                                        v-on:keydown.native="preventEnterEvent"
                                        :allow-minus="true"
                                        name="dcAmtInput"
                                        :disabled="!dcAmtEnabled"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">기예약타임 적용</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group check">
                                    <!-- 포커스 : focus -->
                                    <ul class="check">
                                      <li>
                                        <label>
                                          <input
                                            type="radio"
                                            v-model="
                                              promotionInfo.resveApplyFlag
                                            "
                                            name="resveApplyFlag"
                                            value="true"
                                          />
                                          <i></i>
                                          <div class="label">적용</div>
                                        </label>
                                      </li>
                                      <li>
                                        <label>
                                          <input
                                            type="radio"
                                            v-model="
                                              promotionInfo.resveApplyFlag
                                            "
                                            name="resveApplyFlag"
                                            value="false"
                                          />
                                          <i></i>
                                          <div class="label">미적용</div>
                                        </label>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">비고</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-textarea
                                        ref="remarksInput"
                                        id="remarksInput"
                                        name="remarksInput"
                                        v-model="promotionInfo.remarks"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">이벤트 메모(요금)</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                        v-model="promotionInfo.eventMemo"
                                        @keydown="preventEnterEvent"
                                        name="brcdeventMemonput"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">이벤트 메모(카트)</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                        v-model="promotionInfo.cartAmtMemo"
                                        @keydown="preventEnterEvent"
                                        name="cartAmtMemoInput"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">쿠폰출력 내용</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-textarea
                                        ref="couponPrtContentsInput"
                                        id="remarksInput"
                                        name="couponPrtContentsInput"
                                        v-model="
                                          promotionInfo.couponPrtContents
                                        "
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">쿠폰출력</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group check">
                                    <!-- 포커스 : focus -->
                                    <ul class="check">
                                      <li>
                                        <label>
                                          <input
                                            type="radio"
                                            v-model="
                                              promotionInfo.couponPrtFlag
                                            "
                                            name="couponPrtFlag"
                                            value="true"
                                          />
                                          <i></i>
                                          <div class="label">출력</div>
                                        </label>
                                      </li>
                                      <li>
                                        <label>
                                          <input
                                            type="radio"
                                            v-model="
                                              promotionInfo.couponPrtFlag
                                            "
                                            name="couponPrtFlag"
                                            value="false"
                                          />
                                          <i></i>
                                          <div class="label">미출력</div>
                                        </label>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field">
                              <!-- 필수 : required -->
                              <div class="title">사용여부</div>
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group check">
                                    <!-- 포커스 : focus -->
                                    <ul class="check">
                                      <li>
                                        <label>
                                          <input
                                            type="radio"
                                            v-model="promotionInfo.useFlag"
                                            name="useFlag"
                                            value="true"
                                          />
                                          <i></i>
                                          <div class="label">사용</div>
                                        </label>
                                      </li>
                                      <li>
                                        <label>
                                          <input
                                            type="radio"
                                            v-model="promotionInfo.useFlag"
                                            name="useFlag"
                                            value="false"
                                          />
                                          <i></i>
                                          <div class="label">미사용</div>
                                        </label>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                  id="promotionInfoPopupSave"
                  buttonDiv="SAVE"
                  :is-shortcut-button="true"
                  :isIconCustom="true"
                  @click.native="onSaveClicked"
              >
                저장
              </erp-button>
            </li>
            <li class="apply">
              <erp-button
                  buttonDiv="SAVE"
                  :isIconCustom="true"
                  @click.native="onApplyClicked"
              >
                프로모션 적용
              </erp-button>
            </li>
            <li class="cancel">
              <erp-button
                  buttonDiv="SAVE"
                  :isIconCustom="true"
                  @click.native="onCancelClicked"
              >
                프로모션 취소
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  buttonDiv="CLOSE"
                  @click.native="onCloseClicked"
                  :isIconCustom="true"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import Vue from "vue";

import {
  commonCodesGetCommonCode,
  commonCodeGetComCodeDefaultValue,
} from "@/utils/commonCodes";
import { getFormattedDate } from "@/utils/date";
import commonMixin from "@/views/layout/mixin/commonMixin";
import ErpButton from "@/components/button/ErpButton.vue";
import {
  addPromotion,
  putPromotion,
  getPromotionInfo,
  applyPromotion,
  cancelPromotion,
  deletePromotion,
} from "@/api/promotion";

// multi window 확인1
import InputTime from "@/components/common/datetime/InputTime";
import InputDateRange from "@/components/common/datetime/InputDateRange";
import { validateFormRefs } from "@/utils/formUtil";
import InputNumber from "@/components/common/InputNumber";
import InputText from "@/components/common/text/InputText";
import InputTextarea from "@/components/common/text/InputTextarea";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";


export default {
  // TODO : dialog 용 mixin 하나 제작해서 공통 로직 거기로 넣기
  name: "promotionInfoPopup",
  props: ["popupKey", "value", "position", "zIndex", "isModal", "isVisible"],
  components: {
    InputTextarea,
    InputText,
    InputNumber,
    InputTime,
    InputDateRange,
    ErpButton
  },
  mixins: [commonMixin, confirmDialogMixin],
  created: function () {
    this.promotionInfo.promtnId = this.$props.popupKey;
    this.initValueWhenCreate();
  },
  mounted: function () {
    this.loadData();
  },
  data: function () {
    return {
      isInit: true,
      isPromotionAddPopupOpen: false,
      classCodeFields: { text: "className", value: "classCode" },
      commonCodeFields: { text: "comName", value: "comCode" },
      buttons: {
        browse: "등록",
      },
      dwValueJson: [
        { IDX: 1, ATTRB: "N" },
        { IDX: 2, ATTRB: "N" },
        { IDX: 3, ATTRB: "N" },
        { IDX: 4, ATTRB: "N" },
        { IDX: 5, ATTRB: "N" },
        { IDX: 6, ATTRB: "N" },
        { IDX: 7, ATTRB: "N" },
      ],
      memberGrade: [],
      confirmPopupId: {
        onSaveCheckInConfirmPopup: 15,
        onSaveCheckInSearch: 20,
      },
      promotionList: [],
      DropDownTreeListData: [],
      searchDropDownTreeListData: [],
      paymtCodeEnabled: false,
      dcAmtEnabled: false,
      // Response data 들
      promotionInfo: {
        resveApplyFlag: false,
        couponPrtFlag: false,
        useFlag: true,
        dwValueCheckAll: false,
        startDate: null,
        startTime: "00:00",
        endTime: "00:00",
        endDate: null,
        dwValue: [],
        memberGradeList: [],
        promtnDiv: null,
        resveCourseList: [],
      }, // 프로모션정보
      promotionInfoOptions: {
        // dropdownlist option값 등
        promtnDivOptions: [],
        bsnCodeOptions: [],
        holeDivOptions: [],
        paymtCodeOptions: [],
        dcMethodOptions: [],
        memberGradeOptions: [],
        dwCodeOptions: [],
        bsnCode: null,
        courseCodeOptions: [],
      },
      promotionInfoBaseValue: {
        // 추가 시에 초기화시킬 promotionInfo 값 TODO 안 쓸 예정
        // sexCode: 'M',
        // lunarCode: 'SOLAR',
        // selfCaddieFlag: false
      },
      // multi window 확인
      windowKey: 0,
      header: "",
      animationSettings: { effect: "None" },
      popupPosition: { X: this.$props.position.x, Y: this.$props.position.y },
      currentzIndex: this.$props.zIndex,
      numberTemplate: function () {
        // TODO : 공통 모듈로??
        return {
          template: Vue.component("editOption", {
            template: "<span>{{parseInt(data.index) + 1}}</span>",
            data() {
              return {
                data: {
                  data: {},
                },
              };
            },
          }),
        };
      },
    };
  },
  computed: {
    validateRefList() {
      return {
        promtnDivDropdown: { required: true },
        promtnNmInput: { required: true },
        memberGradeMultiSelect: { required: true },
        promotionDateInput: { required: true },
        bsnCodeDropdown: { required: true },
        startTimeInput: { required: true },
        endTimeInput: { required: true },
        holeDivDropdown: { required: true },
        dcMethodDropdown: { required: true },
        paymtCodeDropdown: { required: this.promotionInfo.dcMethod === 'PAYMT' },
        dcAmtInput: { required: this.promotionInfo.dcMethod !== 'PAYMT' }
      };
    },
    dwValueAllValueModel: {
      get() {
        return (
          this.promotionInfo.dwValue.length ===
          this.promotionInfoOptions.dwCodeOptions.map(({ comCode }) => comCode)
            .length
        );
      },
      set(value) {
        this.promotionInfo.dwValue = value
          ? this.promotionInfoOptions.dwCodeOptions.map(
              ({ comCode }) => comCode
            )
          : [];
      },
    },
    resveCourseAllValueModel: {
      get() {
        return (
          this.promotionInfo.resveCourseList.length ===
            this.promotionInfoOptions.courseCodeOptions.length
        );
      },
      set(value) {
        this.promotionInfo.resveCourseList
          = value ? this.promotionInfoOptions.courseCodeOptions.map(({comCode}) => comCode) : [];
      }
    },
    promotionDateRange: {
      get: function () {
        return {
          from: this.promotionInfo.startDate,
          to: this.promotionInfo.endDate,
        };
      },
      set: function (promotionDateRange) {
        this.promotionInfo.startDate = promotionDateRange.from;
        this.promotionInfo.endDate = promotionDateRange.to;
      },
    },
  },
  methods: {
    validateFormRefs,
    showPopup(promtnId) {
      this.promotionInfo.promtnId = promtnId;
      this.$refs.promotionInfoPopupDialog.show();
    },
    preventEnterEvent: function (event) {
      // TODO : mixin 또는 공통모듈로
      if (event.key === "Enter") {
        event.preventDefault();
      }
    },
    loadData() {
      // 대중소분류 option 값들. 이 값은 매번 새로 조회하여야 하므로 여기에 둠
      console.log(
        "this.promotionInfo.promtnId1 -->",
        this.promotionInfo.promtnId
      );
      // TODO API 호출. 추가 화면인 경우에는 대분류값만 뿌려줌. 수정화면인 경우에는 해당 회원에 해당하는 분류값을 가져와서 뿌려줌
      setTimeout(() => {
        console.log(
          "this.promotionInfo.promtnId2 -->",
          this.promotionInfo.promtnId
        );
        // 추가/수정 시 분기 처리
        if (this.promotionInfo.promtnId) {
          this.setPromotionInfo(this.promotionInfo.promtnId);
        } else {
          // 추가
          this.promotionInfo.promtnId = null;
          this.promotionInfo.dwValue = [];
          this.promotionInfo.resveCourseList = [];
          this.promotionInfo.resveApplyFlag = false;
          this.promotionInfo.promtnDiv = commonCodeGetComCodeDefaultValue(
            "PROMTN_DIV"
          );
        }
      }, 10);
    },
    initValueWhenCreate() {
      // TODO : 공통모듈화
      this.promotionInfo.startDate = getFormattedDate(new Date());
      this.promotionInfo.endDate = getFormattedDate(new Date());
      // 프로모션구분
      this.promotionInfoOptions.promtnDivOptions = commonCodesGetCommonCode(
        "PROMTN_DIV"
      );

      // 영업구분 설정
      this.promotionInfoOptions.bsnCodeOptions = commonCodesGetCommonCode(
        "BSN_CODE"
      );
      if (
        this.promotionInfoOptions.bsnCodeOptions.findIndex(
          (obj) => obj.comCode === "%"
        ) === -1
      ) {
        this.promotionInfoOptions.bsnCodeOptions.unshift({
          comCode: "%",
          comName: "전체",
          codeAbrv: "",
          defaultFlag: false,
        });
      }

      // 영업구분 휴장 제외
      var newBsnCode = this.promotionInfoOptions.bsnCodeOptions.filter(
        (element) => element.comCode !== "CLOSE"
      );
      this.promotionInfoOptions.bsnCodeOptions = newBsnCode;

      // 홀구분 설정
      this.promotionInfoOptions.holeDivOptions = commonCodesGetCommonCode(
        "HOLE_DIV"
      );
      this.promotionInfo.holeDiv = "18";

      // 요금구분 설정
      this.promotionInfoOptions.paymtCodeOptions = commonCodesGetCommonCode(
        "PAYMT_CODE"
      );

      // 할인방법 설정
      this.promotionInfo.dcMethod = commonCodeGetComCodeDefaultValue(
        "DC_METHOD"
      );
      this.promotionInfoOptions.dcMethodOptions = commonCodesGetCommonCode(
        "DC_METHOD"
      );

      // 회원등급
      this.promotionInfoOptions.memberGradeOptions = commonCodesGetCommonCode(
        "MEMBER_GRADE"
      );

      // 출발코스
      this.promotionInfoOptions.courseCodeOptions = commonCodesGetCommonCode("COURSE_CODE");

      // 요일
      this.promotionInfoOptions.dwCodeOptions = commonCodesGetCommonCode(
        "DW_CODE"
      );
      this.dwValueJson = [
        { IDX: 1, ATTRB: "N" },
        { IDX: 2, ATTRB: "N" },
        { IDX: 3, ATTRB: "N" },
        { IDX: 4, ATTRB: "N" },
        { IDX: 5, ATTRB: "N" },
        { IDX: 6, ATTRB: "N" },
        { IDX: 7, ATTRB: "N" },
      ];
    },
    /*
        initValueWhenClose () {
        this.promotionInfo.promtnId = null
      },
      */
    dwValueCheckAllCheck: function () {
      if (this.promotionInfo.dwValueCheckAll === true) {
        this.promotionInfo.dwValue = ["1", "2", "3", "4", "5", "6", "7"];
      } else {
        this.promotionInfo.dwValue = [];
      }
    },
    dialogClose: function () {
      // this.initValueWhenClose()
      this.$emit(
        "popupEvent",
        "popupClosed",
        this.$props.popupKey,
        this.$props.value
      );
    },
    dialogOpen: function () {
      // document.getElementById('dlgbtn').style.display = 'none'
    },
    dlgBtnClick: function () {},
    onDialogClicked: function () {
      this.$emit(
        "popupEvent",
        "popupClicked",
        this.$props.popupKey,
        this.$props.value
      );
    },
    setZIndex: function (zIndex) {
      this.currentzIndex = zIndex;
    },
    onConfirmPopupCancelClicked(popupId) {
      // confirm popup 이벤트 핸들링 처리. Open시 넣었던 popupId를 기준으로 분기함
      switch (popupId) {
        case this.confirmPopupId.onSaveCheckInConfirmPopup: // 취소 시 URL 변경
          if (this.promotionInfo.promtnId) {
            this.$router.push({
              name: "samplememberInfoAddModified",
              params: { promotionInfo: this.promotionInfo.promtnId },
            });
          } else {
            this.$router.push({ name: "samplememberInfoAddModified" });
          }
          break;
        default:
          break;
      }
    },
    setPromotionInfo(promtnId) {
      getPromotionInfo(promtnId)
        .then((response) => {
          // row 선택 유지 TODO : 공통 모듈로 뺴기
          this.promotionInfo = response.value.promotion;
          if (this.promotionInfo.dwValue !== undefined) {
            this.promotionInfo.dwValue = this.promotionInfo.dwValue.split(",");
          } else {
            this.promotionInfo.dwValue = [];
          }
          if (!this.promotionInfo.resveCourseList) {
            console.log('1.');
            this.promotionInfo.resveCourseList = [];
          }
          // this.promotionLength = this.promotionList.length
        })
        .catch((error) => {
          console.log("getPromotionInfo.err.===>", error);
        });
    },
    onStartTimeChange(args) {
      if (!args.data) {
        return;
      }
      if (this.promotionInfo.startTime > this.promotionInfo.endTime) {
        this.promotionInfo.endTime = this.promotionInfo.startTime;
      }
    },
    onEndTimeChange(args) {
      if (!args.data) {
        return;
      }
      if (this.promotionInfo.startTime > this.promotionInfo.endTime) {
        this.promotionInfo.startTime = this.promotionInfo.endTime;
      }
    },
    onDcMethodChange() {
      if (
        this.promotionInfo.dcMethod === "AMT" ||
        this.promotionInfo.dcMethod === "RATE"
      ) {
        this.promotionInfo.paymtCode = null;
        this.paymtCodeEnabled = false;
        this.dcAmtEnabled = true;
      } else {
        this.promotionInfo.dcAmt = null;
        this.paymtCodeEnabled = true;
        this.dcAmtEnabled = false;
      }
    },
    async onApplyClicked() {
      if (
        await this.confirm(
          this.promotionInfo.startDate +
            "~" +
            this.promotionInfo.endDate +
            " 프로모션을 적용하시겠습니까?"
        )
      ) {
        this.onSaveClicked().then(() => {
          applyPromotion(this.promotionInfo.promtnId)
            .then((response) => {
              if (response.status === "OK") {
                const {applyConfirmCount, applyTimeCount} = response.value;
                if (applyConfirmCount < 1 && applyTimeCount < 1) {
                  this.errorToast("적용된 프로모션이 없습니다");
                } else {
                  this.infoToast(this.$t("main.popupMessage.saved"));
                }
                this.setPromotionInfo(this.promotionInfo.promtnId);
                this.$emit(
                  "popupEvent",
                  "promotionManagementSaved",
                  this.$props.popupKey,
                  this.$props.value
                );
                this.$refs.promotionInfoPopupDialog.hide();
              }
            })
            .catch((error) => {
              console.log("applyPromotion.err.===>", error);
            });
        });
      }
    },
    async onCancelClicked() {
      if (
        await this.confirm(
          this.promotionInfo.startDate +
            "~" +
            this.promotionInfo.endDate +
            "프로모션을 취소하시겠습니까?"
        )
      ) {
        await this.onSaveClicked();
        cancelPromotion(this.promotionInfo.promtnId)
          .then((response) => {
            if (response.status === "OK") {
              this.setPromotionInfo(this.promotionInfo.promtnId);
              this.$emit(
                "popupEvent",
                "promotionManagementSaved",
                this.$props.popupKey,
                this.$props.value
              );
            }
          })
          .catch((error) => {
            console.log("cancelPromotion.err.===>", error);
          });
      }
    },
    getDataFields(item) {
      return item.name.toUpperCase() === this.findDataFieldName.toUpperCase();
    },
    setDropDownListName(source, datafield, data) {
      const dataFieldName = datafield + "_DROPDOWNLIST_VALUE_NAME";
      this.findDataFieldName = dataFieldName;
      const findIndex = source.datafields.findIndex(this.getDataFields);
      if (findIndex >= 0) {
        source.datafields[findIndex].values.source = data;
      }
    },
    formInit() {
      // default 설정
      this.initValueWhenCreate();
    },
    onInitClicked() {
      this.formInit();
    },
    onSaveClicked() {
      return new Promise((resolve, reject) => {
        if (this.validate()) {
          if (!(this.promotionInfo.dwValue.length > 0)) {
            return this.errorToast("요일구분을 선택해 주십시오.");
          }
          if (!(this.promotionInfo.resveCourseList.length > 0)) {
            return this.errorToast("출발코스를 선택해 주십시오");
          }
          if (
            this.promotionInfo.dcMethod !== "PAYMT" &&
            this.promotionInfo.dcAmt === 0
          ) {
            this.$refs.dcAmtInput.focus();
            return this.errorToast(
              this.$t("main.validationMessage.requiredMessageNoParam")
            );
          }
          for (let i = 0; i < this.dwValueJson.length; i++) {
            this.dwValueJson[i].ATTRB = "N";
            for (let j = 0; j < this.promotionInfo.dwValue.length; j++) {
              if (
                this.dwValueJson[i].IDX.toString() ===
                this.promotionInfo.dwValue[j].toString()
              ) {
                this.dwValueJson[i].ATTRB = "Y";
              }
            }
          }
          this.savePromotionInfo()
            .then(() => {
              resolve();
            })
            .catch(() => {
              reject();
            });
        } else {
          reject();
        }
      });
    },
    savePromotionInfo() {
      return new Promise((resolve, reject) => {
        if (
          this.promotionInfo.promtnId === null ||
          this.promotionInfo.promtnId === "" ||
          this.promotionInfo.promtnId === undefined
        ) {
          this.promotionInfo.memberGrade = this.promotionInfo.memberGradeList;
          this.promotionInfo.dwValueJson = JSON.stringify(this.dwValueJson);
          if (this.validate()) {
            addPromotion(this.promotionInfo)
              .then((response) => {
                this.promotionInfo.promtnId = response.value;
                if (response.status === "OK") {
                  this.setPromotionInfo(this.promotionInfo.promtnId);
                  this.$emit(
                    "popupEvent",
                    "promotionManagementSavedByAdd",
                    this.$props.popupKey,
                    this.$props.value,
                    this.promotionInfo.promtnId
                  );
                  // this.$emit('rowSelect', this.promotionInfo.promtnId);
                  resolve();
                }
              })
              .catch((error) => {
                reject();
                console.log("addPromotion.err.===>", error);
              });
          } else {
            reject();
          }
        } else {
          this.promotionInfo.dwValueJson = JSON.stringify(this.dwValueJson);
          this.promotionInfo.memberGrade = this.promotionInfo.memberGradeList;
          if (this.validate()) {
            putPromotion(this.promotionInfo)
              .then((response) => {
                if (response.status === "OK") {
                  this.setPromotionInfo(this.promotionInfo.promtnId);
                  this.$emit(
                    "popupEvent",
                    "promotionManagementSaved",
                    this.$props.popupKey,
                    this.$props.value
                  );
                  resolve();
                }
              })
              .catch((error) => {
                reject();
                console.log("putPromotion.err.===>", error);
              });
          } else {
            reject();
          }
        }
      });
    },
    async onDeleteClicked() {
      if (await this.confirm("프로모션을을 삭제하시겠습니까?")) {
        deletePromotion(this.promotionInfo.promtnId)
          .then((response) => {
            if (response.status === "OK") {
              this.$emit(
                "popupEvent",
                "promotionManagementDeleted",
                this.$props.popupKey,
                this.$props.value
              );
              this.$refs.promotionInfoPopupDialog.hide();
              this.infoToast(this.$t("main.popupMessage.deleted"));
            }
          })
          .catch((error) => {
            console.log("deletePromotion.err.===>", error);
          });
      }
    },
    onCloseClicked() {
      // this.initValueWhenClose()
      this.$refs.promotionInfoPopupDialog.hide();
    },
    validate() {
      return this.validateFormRefs(this.validateRefList);
    },
  },
};
</script>
